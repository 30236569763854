<template>
  <div>
    <molecules-custom-dialog
      :show="showRemove"
      title="Apakah Anda Yakin?"
      :caption="`Anda akan menghapus artikel?`"
      :items="[
        {
          title: 'Batalkan',
          full: false,
          exec: () => {
            showRemove = null
          },
        },
        {
          title: 'Iya Hapus',
          override: 'red--text',
          full: false,
          exec: () => {
            remove(showRemove)
            showRemove = null
          },
        },
      ]"
    />
    <v-row>
      <v-col cols="12">
        <molecules-summary-card
          icon="newspaper-variant-multiple"
          :title="`${$store.state.article.count || 0} Total Artikel`"
          :desc="`Tanggal ${$moment().format('DD MMMM YYYY')}`"
          :loading="reportLoading"
        />
      </v-col>
    </v-row>

    <v-form @submit.prevent="loadData" id="filter">
      <atoms-text class="pa-2"><v-icon left>mdi-magnify</v-icon><span>Pencarian</span></atoms-text>
      <v-row justify="space-between" align="center">
        <v-col cols="12" md="8">
          <atoms-text-field
            label="Cari Blog, ketikkan judul dll.."
            v-model.trim="term"
            :noLabel="true"
            :appendAction="
              () => {
                term = ''
                loadData()
              }
            "
            appendIcon="mdi-close"
          />
        </v-col>
        <v-col cols="12" md="2" class="d-flex justifyx-space-between">
          <atoms-button type="submit" style="width: 100%" class="primary lighten-5 primary--text"
            ><v-icon left>mdi-magnify</v-icon>Cari Artikel</atoms-button
          >
        </v-col>
        <v-col cols="12" md="2" class="d-flex justify-space-between">
          <atoms-button @click="showSubmit = true" style="width: 100%" class="primary lighten-5 primary--text"
            ><v-icon left>mdi-plus</v-icon>Tambah Artikel</atoms-button
          >
        </v-col>
        <v-col cols="12" md="8">
          <v-row align="center" dense no-gutters>
            <v-col cols="12" md="2">
              <atoms-button
                class="primary"
                style="width: 100%"
                @click="
                  () => {
                    sort = sort === constant[0] ? constant[1] : constant[0]
                    getArticle()
                  }
                "
                ><v-icon left>mdi-filter-variant-{{ sort === constant[0] ? 'minus' : 'plus' }}</v-icon
                >Sort: {{ sort }}</atoms-button
              >
            </v-col>
            <v-col class="grow d-flex justify-center justify-md-start ml-md-4 align-center">
              <v-checkbox v-model="enableDate" hide-spin-buttons @change="getArticle" /><atoms-text
                >Filter dengan tanggal dibuat</atoms-text
              >
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="4">
          <v-combobox
            class="rounded-lg"
            placeholder="Filters"
            prepend-inner-icon="mdi-filter-variant"
            :items="filters.map((x) => x.title)"
            v-model="filter"
            @change="loadData"
            hide-details
            multiple
            dense
            solo
            flat
            required
            :style="{
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }"
        /></v-col>

        <!-- date -->
        <v-scroll-y-transition>
          <v-col cols="12" v-if="enableDate" class="py-0">
            <v-row dense>
              <v-col cols="12" md="6">
                <atoms-date-picker
                  label="Dari Tanggal"
                  :modal="from.modal"
                  :value="from.value"
                  @change="
                    (modal, value) => {
                      from.modal = modal
                      from.value = value
                    }
                  "
                />
              </v-col>
              <v-col cols="12" md="6">
                <atoms-date-picker
                  label="Sampai Tanggal"
                  :modal="to.modal"
                  :value="to.value"
                  @change="
                    (modal, value) => {
                      to.modal = modal
                      to.value = value
                    }
                  "
                />
              </v-col>
            </v-row>
          </v-col>
        </v-scroll-y-transition>
      </v-row>
    </v-form>

    <div class="mt-4">
      <div v-if="mainLoading">
        <v-skeleton-loader
          v-for="i in 5"
          :key="i"
          type="list-item-avatar"
          class="accent rounded-lg mb-2 pa-2"
          :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          }"
        />
      </div>
      <div v-else>
        <div v-if="$store.state.article.data && $store.state.article.data.length > 0">
          <!-- <atoms-text class="pa-2"
            ><v-icon>mdi-magnify</v-icon>Hasil Pencarian, {{ items.count }} data
            ditemukan</atoms-text
          > -->
          <div
            v-for="({ picture, title, status, ...rest }, i) in $store.state.article.data || []"
            :key="i"
            :class="['accent', 'rounded-lg pa-4 mb-2']"
            :style="{
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }"
          >
            <v-row align="center">
              <v-col class="shrink">
                <atoms-image
                  :width="150"
                  :height="100"
                  :enableZoom="true"
                  :src="picture"
                  class="rounded-lg background"
                />
              </v-col>
              <v-col cols="8" md="4">
                <atoms-text :auto="true" class="font-weight-bold">{{
                  $toCapitalize(title || 'Judul tidak dicantumkan')
                }}</atoms-text>
                <atoms-text class="primary--text">{{
                  ($areIncludes($store.state.user.roles, [$roles.Worker, $roles.Freelance]) &&
                    status === 'Drafted' &&
                    'Review (Unpublished)') ||
                  status ||
                  '-'
                }}</atoms-text>
              </v-col>
              <v-col align="start">
                <atoms-text class="font-weight-bold"
                  >Dibuat pada
                  {{ $moment(rest._createdDate).format('dddd, DD MMMM YYYY') }}
                  <span v-if="rest.createdByData">
                    oleh
                    {{ rest.createdByData.nickname || '-' }}
                  </span>
                </atoms-text>
                <atoms-text class="primary--text"
                  >Disunting pada
                  {{ $moment(rest._updatedDate).format('dddd, DD MMMM YYYY') }}
                  <span v-if="rest.updatedByData">oleh {{ rest.updatedByData.nickname || '-' }}</span>
                </atoms-text>
              </v-col>
              <!-- <v-col align="start">
                
              </v-col> -->
              <v-col class="shrink">
                <v-menu offset-x class="rounded-xl">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                  </template>
                  <v-list nav dense elevation="0" class="text-center rounded-lg">
                    <v-list-item link @click="showDetail = rest._id" class="rounded-lg px-4 text-center">
                      <v-icon left>mdi-view-list-outline</v-icon>
                      <v-list-item-title class="px-2">Detail</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="showEdit = rest._id" class="rounded-lg px-4 text-center">
                      <v-icon left>mdi-pencil</v-icon>
                      <v-list-item-title class="px-2">Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      link
                      v-if="$store.state.user.roles.includes($roles.Super_Admin)"
                      @click="showRemove = rest._id"
                      class="rounded-lg px-4 text-center red white--text"
                    >
                      <v-icon left dark>mdi-delete</v-icon>
                      <v-list-item-title class="px-2">Hapus</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            <v-container>
              <atoms-text class="orange--text">Penulis : {{ (rest.author && rest.author.nickname) || '-' }}</atoms-text>
              <!-- Promo berakhir {{ $moment(rest.endDate).fromNow() }} ({{
                  $moment(rest.endDate).format("dddd, DD MMMM YYYY")
                }}) -->
              <atoms-text> {{ rest.excerpt && rest.excerpt }}</atoms-text>
            </v-container>
          </div>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination class="my-4" v-model="page" :length="$store.state.article.pagesLength"></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </div>
        <molecules-wrapper v-else class="d-flex justify-center align-center my-8 py-8">
          <div class="text-center">
            <atoms-image :width="250" src="/assets/missing.svg" />
            <atoms-title :h3="true" class="mt-6">Tidak ada artikel yang ditemukan!</atoms-title>
          </div>
        </molecules-wrapper>
      </div>
    </div>
    <v-slide-x-reverse-transition>
      <organism-article-submit
        v-if="showSubmit || showEdit"
        :id="showEdit"
        @close="
          () => {
            showSubmit = false
            showEdit = null
          }
        "
      />
    </v-slide-x-reverse-transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      term: '',
      page: 1,
      limit: 10,
      showDetail: null,
      showSubmit: false,
      showRemove: null,
      showEdit: null,
      reportLoading: false,
      mainLoading: false,
      sort: '',
      constant: ['Desc', 'Asc'],
      isUseDatePromo: false,
      filter: null,
      enableDate: false,
      from: {
        modal: false,
        value: this.$moment().startOf('month').format('YYYY-MM-DD'),
      },
      to: {
        modal: false,
        value: this.$moment().format('YYYY-MM-DD'),
      },
      filters: [
        {
          title: 'Semua',
          value: {
            status: undefined,
          },
        },
        {
          title: this.$postStatus[0], // Published
          value: {
            status: this.$postStatus[0],
          },
        },
        {
          title: this.$postStatus[1], // Drafted
          value: {
            status: this.$postStatus[1],
          },
        },
      ],
    }
  },
  watch: {
    page: {
      handler() {
        this.getArticle()
      },
      deep: true,
    },
  },
  async mounted() {
    if (this.$areIncludes(this.$store.state.user?.roles, [this.$roles.Worker, this.$roles.Freelance])) {
      // edit drafted into, review
      this.filters[2].title = 'Review'
    }
    this.filter = [this.filters[0].title]
    this.sort = this.constant[0]
    this.loadData()
  },
  methods: {
    loadData() {
      this.page = 1
      this.getArticle()
      this.getSummary()
      this.$vuetify.goTo(0)
    },
    async remove(payload) {
      this.$store.commit('SET', { loading: true })
      try {
        if (payload) {
          await this.$store.dispatch('article/remove', payload)
          this.$showDialog({
            title: 'Berhasil',
            body: `Artikel berhasil dihapus!`,
          })
          this.loadData()
        }
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async getSummary() {
      try {
        this.reportLoading = true
        await this.$store.dispatch('article/getSummary')
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.reportLoading = false
      }
    },
    async getArticle() {
      this.mainLoading = true
      try {
        await this.$store.dispatch('article/getAll', {
          custom: {
            title: { $regex: this.term || '', $options: 'i' },
            ...(this.filter && this.filter.length > 0
              ? Object.assign({}, ...this.filter?.map((x) => this.filters?.find((y) => y.title === x)?.value))
              : []),
            _createdDate: this.enableDate
              ? {
                  $gte: new Date(new Date(this.from.value).setHours(0, 0, 0, 0)),
                  $lt: new Date(new Date(this.to.value).setHours(45, 0, 0, 0)),
                }
              : { $exists: true },
            pipeline: [
              {
                $sort: {
                  _createdDate: this.sort === this.constant[0] ? -1 : 1,
                },
              },
              ...this.$store.state.article?.defaultPipeline,
            ],
          },
          page: this.page,
          limit: this.limit,
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.mainLoading = false
      }
    },
  },
}
</script>
